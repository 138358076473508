import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { HeadingSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import * as React from "react";

import { useTranslation } from "../../../hooks/translate";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { scrollWithOffset } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, Row } from "../../containers";
import { FontFamily, LP_BUTTON_PRIMARY, USE_FONT } from "../../overrides";
import { PromoButton } from "../../promo-button";
import americanIcon from "./assets/american.png";
import clockIcon from "./assets/clock.png";
import moneyIcon from "./assets/money.png";
import refundIcon from "./assets/refund.png";
import scheduleIcon from "./assets/schedule.png";
import speedIcon from "./assets/speed.png";
import { STYLES } from "./styles";

export const LpBenefitsSection = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const { width: screenWidth } = useScreenSize();

  const getIcon = (icon: string) => {
    const style = {
      height: "fit-content",
      marginLeft: theme.sizing.scale600,
      width: "78px",
    };
    switch (icon) {
      case "clock":
        return (
          <img alt="save time while traveling" src={clockIcon} style={style} />
        );
      case "money":
        return (
          <img alt="save money while traveling" src={moneyIcon} style={style} />
        );
      case "refund":
        return (
          <img
            alt="100% refund policy, no questions asked"
            src={refundIcon}
            style={style}
          />
        );
      case "schedule":
        return (
          <img
            alt="activate only when needed"
            src={scheduleIcon}
            style={style}
          />
        );
      case "speed":
        return (
          <img
            alt="fast data connection while traveling"
            src={speedIcon}
            style={style}
          />
        );
      case "support":
        return (
          <img
            alt="english support while traveling abroad"
            src={americanIcon}
            style={style}
          />
        );
    }
    return null;
  };

  const items = [
    {
      description: translate("lp.benefits.items.first.caption"),
      icon: getIcon(translate("lp.benefits.items.first.icon")),
      title: translate("lp.benefits.items.first.heading"),
    },
    {
      description: translate("lp.benefits.items.second.caption"),
      icon: getIcon(translate("lp.benefits.items.second.icon")),
      title: translate("lp.benefits.items.second.heading"),
    },
    {
      description: translate("lp.benefits.items.third.caption"),
      icon: getIcon(translate("lp.benefits.items.third.icon")),
      title: translate("lp.benefits.items.third.heading"),
    },
    {
      description: translate("lp.benefits.items.fourth.caption"),
      icon: getIcon(translate("lp.benefits.items.fourth.icon")),
      title: translate("lp.benefits.items.fourth.heading"),
    },
    {
      description: translate("lp.benefits.items.fifth.caption"),
      icon: getIcon(translate("lp.benefits.items.fifth.icon")),
      title: translate("lp.benefits.items.fifth.heading"),
    },
    {
      description: translate("lp.benefits.items.sixth.caption"),
      icon: getIcon(translate("lp.benefits.items.sixth.icon")),
      title: translate("lp.benefits.items.sixth.heading"),
    },
  ];

  return (
    <Row className={css(STYLES.container(theme))}>
      <Column className={css(STYLES.wrapper)}>
        <Column className={css(STYLES.headingWrapper(theme))}>
          <HeadingSmall
            style={USE_FONT(FontFamily.SatoshiVariable, 700, "38px", "26px")}
          >
            {translate("lp.benefits.heading")}
          </HeadingSmall>
          <ParagraphMedium
            style={USE_FONT(FontFamily.DMSans, 400, "24px", "16px")}
          >
            {translate("lp.benefits.caption")}
          </ParagraphMedium>
        </Column>
        <Row className={css(STYLES.grid(theme, screenWidth))}>
          {items.map((item, index) => (
            <Row
              className={css(STYLES.gridItem(theme))}
              key={`benefit-${index}`}
            >
              <Column
                style={{
                  marginBottom: theme.sizing.scale600,
                  maxWidth: "435px",
                }}
              >
                <LabelMedium
                  style={USE_FONT(
                    FontFamily.SatoshiVariable,
                    700,
                    "32px",
                    "20px",
                  )}
                >
                  {item.title}
                </LabelMedium>
                <ParagraphMedium
                  style={USE_FONT(FontFamily.DMSans, 400, "26px", "14px")}
                >
                  {item.description}
                </ParagraphMedium>
              </Column>
              {item.icon}
            </Row>
          ))}
        </Row>
        <PromoButton
          content="Claim Discount"
          style={{
            marginBottom: theme.sizing.scale600,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.sizing.scale900,
          }}
        >
          <Button
            onClick={() => {
              Mixpanel.track("CTA", {
                action: "click",
                element: "get-access-now",
                section: "benefits",
              });
              scrollWithOffset("#purchase-form", -240);
            }}
            overrides={LP_BUTTON_PRIMARY(theme, {
              ...USE_FONT(FontFamily.DMSans, 500, "20px", "18px"),
              paddingLeft: theme.sizing.scale1200,
              paddingRight: theme.sizing.scale1200,
            })}
          >
            {translate("lp.benefits.button")}
          </Button>
        </PromoButton>
      </Column>
    </Row>
  );
};
